/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, Menu, ColumnWrapper, MenuWrap, MenuButton, MenuWrapBox, MenuButtonClose, Button, ColumnCover, ColumnWrap, Title, Text, SeparateLine, SeparateLineWrap, Subtitle, ContactForm, Fullmap, FullmapWrap, FullmapCover } from '@swp/components'


export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"Súkromná materská škola EBG Brezno výberové konanie"}>
        <Column className="--menu pb--08 pt--08" menu={true} name={"wbvvxnz7omq"}>
          
          <Menu >
            
            <ColumnWrapper className="menu-logo-box">
            </ColumnWrapper>

            <MenuWrap >
              
              <MenuButton >
              </MenuButton>

              <MenuWrapBox className="--style3">
                
                <MenuButtonClose >
                </MenuButtonClose>

                <Button className="btn-box btn-box--hvr5 btn-box--cbtn2 btn-box--sbtn1 btn-box--shape4 btn-box--design3 btn-box--shadow5 btn-box--filling3" href={"/"} target={""} content={"<span style=\"color: rgb(255, 255, 255);\">Domov</span>"}>
                </Button>

                <Button className="btn-box btn-box--hvr5 btn-box--cbtn2 btn-box--sbtn1 btn-box--shape4 btn-box--design3 btn-box--shadow5 btn-box--filling3" href={"#drk6ovbmfgg"} target={""} content={"<span style=\"color: rgb(255, 255, 255);\">Kontakt</span>"}>
                </Button>

              </MenuWrapBox>

            </MenuWrap>

          </Menu>

        </Column>


        <ColumnCover name={"70cgnjbr2o6"}>
        </ColumnCover>


        <Column className="css-1cn56f9 --style3 bg--top --full mb--0 mt--0 pb--0 pt--80" name={"sx455k3fdkq"} fullscreen={true} css={css`
      
    background-image: url(https://cdn.swbpg.com/t/7662/4833c44473074e8ebab21cd366908dc0_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/7662/4833c44473074e8ebab21cd366908dc0_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: url(https://cdn.swbpg.com/t/7662/4833c44473074e8ebab21cd366908dc0_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/7662/4833c44473074e8ebab21cd366908dc0_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: url(https://cdn.swbpg.com/t/7662/4833c44473074e8ebab21cd366908dc0_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: url(https://cdn.swbpg.com/t/7662/4833c44473074e8ebab21cd366908dc0_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/7662/4833c44473074e8ebab21cd366908dc0_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/7662/4833c44473074e8ebab21cd366908dc0_s=3000x_.jpg);
    }
  
    `}>
          
          <ColumnWrap className="--left el--1 flex--center" columns={"1"}>
            
            <ColumnWrapper className="--right">
              
              <Title className="title-box title-box--style2 title-box--shadow4 title-box--center fs--62 pb--60 pl--40 pr--25 pt--30" style={{"maxWidth":1478}} content={"Výberové konanie Súkromnej materskej školy EBG<br>"}>
              </Title>

              <Text className="text-box" content={""}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="--left el--1 flex--center" columns={"1"}>
          </ColumnWrap>

        </Column>


        <SeparateLine className="pb--10 pt--10" name={"rrpiybsud2h"} layout={"l1"}>
          
          <SeparateLineWrap className="--full" fullscreen={true}>
          </SeparateLineWrap>

        </SeparateLine>


        <Column className="pb--60 pt--60" name={"6ojjxvbdy04"}>
          
          <ColumnWrap className="column__flex el--1 flex--bottom" style={{"maxWidth":1573}} columns={"1"}>
            
            <ColumnWrapper className="--left">
              
              <Title className="title-box title-box--justify fs--43 w--900" content={"<span style=\"color: rgb(159, 61, 61);\">Výberové konanie na riaditeľa Súkromnej materskej školy EBG, Školská 5, 977 01 Brezno</span>"}>
              </Title>

              <Text className="text-box" style={{"maxWidth":1531}} content={"Európske vzdelávacie strediská pre povolanie a spoločnosť, Hronská 696/107, Závadka nad Hronom 976 67, v zastúpení Ing. Marekom Niklom, v zmysle § 4 zákona NR SR č. 596/2003 Z. z. o štátnej správe v školstve a školskej samospráve a o zmene a doplnení niektorých zákonov v znení neskorších predpisov a § 5 zákona č. 552/2003 Z. z. o výkone práce vo verejnom záujme v znení neskorších predpisov"}>
              </Text>

              <Subtitle className="subtitle-box" content={"Vyhlasuje výberové konanie na obsadenie funkcie :\n<br>Riaditeľ Súkromnej materskej školy EBG, Školská 5, 977 01 Brezno&nbsp;"}>
              </Subtitle>

              <Subtitle className="subtitle-box subtitle-box--justify" content={"s termínom nástupu do funkcie: <span style=\"font-style: italic; color: rgb(216, 0, 0);\">17. 10.2022</span>"}>
              </Subtitle>

              <Subtitle className="subtitle-box subtitle-box--justify" content={"Kvalifikačné predpoklady:"}>
              </Subtitle>

              <Text className="text-box text-box--justify" style={{"maxWidth":1519}} content={"•\tvzdelanie podľa § 11 a 12 zákona č. 138/2019 Z. z. o pedagogických zamestnancoch a odborných zamestnancoch a o zmene a doplnení niektorých zákonov v znení neskorších predpisov a podľa vyhlášky MŠVVaŠ SR č. 1/2020 o kvalifikačných predpokladoch pedagogických zamestnancov a odborných zamestnancov – najmenej úplné stredné odborné vzdelanie na výkon pracovnej činnosti v príslušnej kategórii a podkategórii pedagogického zamestnanca,\n<br>•\tsplnenie podmienky zaradenia do kariérového stupňa samostatný pedagogický zamestnanec,\n<br>•\tnajmenej 5 rokov pedagogickej praxe,\n<br>•\tovládanie štátneho jazyka,\n<br>•\tznalosť príslušnej legislatívy, organizačné schopnosti a komunikatívnosť,\n<br>•\tosobnostné a morálne predpoklady,\n<br>•\torganizačné a riadiace schopnosti,\n<br>•\taktívna znalosť práce s PC (Word, Excel, internet),\n<br>•\tobčianska bezúhonnosť,\n<br>•\tzdravotná spôsobilosť.&nbsp;<br>"}>
              </Text>

              <Text className="text-box text-box--justify" style={{"maxWidth":""}} content={"<span style=\"font-weight: bold;\">Ďalšie požiadavky podľa § 3 zákona č. 552/2003 Z. z. o výkone práce vo verejnom záujme v znení neskorších predpisov:</span>"}>
              </Text>

              <Text className="text-box" style={{"maxWidth":""}} content={"•\tspôsobilosť na právne úkony v plnom rozsahu,<br>•\tbezúhonnosť,<br>•\tmanažérske schopnosti pre riadiacu činnosť,\n<br>•\tznalosť práce s PC,\n<br>•\tkomunikatívnosť&nbsp;<br>"}>
              </Text>

              <Text className="text-box text-box--justify" style={{"maxWidth":""}} content={"<span style=\"font-weight: bold;\">Požadované doklady:</span>"}>
              </Text>

              <Text className="text-box text-box--justify" style={{"maxWidth":""}} content={"•\tprihláška do výberového konania,\n<br>•\toverené kópie dokladov o najvyššom dosiahnutom vzdelaní,\n<br>•\tvýpis z registra trestov nie starší ako tri mesiace,\n<br>•\tprofesijný životopis,\n<br>•\tdoklad o dĺžke výkonu pedagogickej činnosti,\n<br>•\tpotvrdenie o zdravotnej spôsobilosti (podľa § 6 zák. č. 317/2009 Z.z.)&nbsp;<br>•\tpísomný návrh koncepcie rozvoja školy (v zmysle §4 ods. 1 zákona č. 596/2003 Z. z.),\n<br>•\tsúhlas uchádzača na použitie osobných údajov pre potreby výberového konania v zmysle § 11 zákona č. 18/2018 o ochrane osobných údajov a o zmene a doplnení niektorých zákonov&nbsp;<br>"}>
              </Text>

              <Subtitle className="subtitle-box subtitle-box--justify" content={"Žiadosť s požadovanými dokladmi do výberového konania posielajte (doručte) do <span style=\"color: rgb(255, 0, 0);\">27.09.2022 do 14.00 h. na adresu: Súkromná materská škola EBG, Ing. Marek Nikel, Školská 5, 977 01 Brezno</span> s označením na obálke:"}>
              </Subtitle>

              <Subtitle className="subtitle-box subtitle-box--justify" content={"„Výberové konanie na funkciu riaditeľa Súkromnej materskej školy EBG, Školská 5, 977 01 Brezno. NEOTVÁRAŤ !\nDátum a miesto uskutočnenia výberového konania bude uchádzačom, ktorí splnia podmienky, oznámené najmenej 7 dní pred jeho uskutočnením.\n"}>
              </Subtitle>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"6ojjxvbdy04"}>
          
          <ColumnWrap className="column__flex el--1 flex--bottom" style={{"maxWidth":1573}} columns={"1"}>
            
            <ColumnWrapper className="--left">
              
              <Title className="title-box title-box--justify fs--43 w--900" content={"<span style=\"color: rgb(154, 43, 43);\">Súkromná materská škola EBG, Školská 5, 977 01 Brezno vyhlasuje výberové konanie na pozíciu pedagogického asistenta/ asistenta učiteľa.</span><br>"}>
              </Title>

              <Text className="text-box" style={{"maxWidth":1531}} content={"Európske vzdelávacie strediská pre povolanie a spoločnosť, Hronská 696/107, Závadka nad Hronom 976 67, v zastúpení Ing. Marekom Niklom, v zmysle § 4 zákona NR SR č. 596/2003 Z. z. o štátnej správe v školstve a školskej samospráve a o zmene a doplnení niektorých zákonov v znení neskorších predpisov a § 5 zákona č. 552/2003 Z. z. o výkone práce vo verejnom záujme v znení neskorších predpisov"}>
              </Text>

              <Subtitle className="subtitle-box" content={"Úväzok: plný"}>
              </Subtitle>

              <Subtitle className="subtitle-box subtitle-box--justify" content={"s termínom nástupu do funkcie: <span style=\"font-style: italic; color: rgb(216, 0, 0);\">01. 11.2020</span>"}>
              </Subtitle>

              <Subtitle className="subtitle-box subtitle-box--justify" content={"Pracovné podmienky: pracovný pomer na dobu určitú do 31.08. 2022<br>Kvalifikačné predpoklady:<br>"}>
              </Subtitle>

              <Text className="text-box text-box--justify" style={{"maxWidth":1519}} content={"SPLNENIE KVALIFIKAČNÝCH PREDPOKLADOV:<br>V zmysle vyhlášky MŠVVaŠ SR č. 1/2020 Z.z. o kvalifikačných predpokladoch pedagogických zamestnancov a odborných zamestnancov. <br>Min. úplné stredoškolské pedagogické vzdelanie (v zmysle vyhlášky MŠVVaŠ SR č. 1/2020 Z.z. o kvalifikačných predpokladoch pedagogických zamestnancoch a odborných zamestnancoch- účinná od 15.01 2020)<br>"}>
              </Text>

              <Text className="text-box text-box--justify" style={{"maxWidth":""}} content={"<span style=\"font-weight: bold;\">Ďalšie požiadavky:</span>"}>
              </Text>

              <Text className="text-box" style={{"maxWidth":""}} content={"• schopnosť pracovať v tíme&nbsp;<br>• profesionalita a odbornosť<br>• zodpovednosť<br>• samostatnosť<br>• flexibilita<br>• komunikatívnosť<br>• bezúhonnosť<br>• zdravotná spôsobilosť<br>• ovládanie štátneho jazyka<br>"}>
              </Text>

              <Text className="text-box text-box--justify" style={{"maxWidth":""}} content={"<span style=\"font-weight: bold;\">Požadované doklady:</span>"}>
              </Text>

              <Text className="text-box text-box--justify" style={{"maxWidth":""}} content={"• žiadosť s telefónnym kontaktom a emailovým kontaktom, <br>• štruktúrovaný životopis v podobe europassu,<br>•&nbsp;fotokópie dokladov o vzdelaní a pedagogickej praxi, <br>• písomný súhlas uchádzača na použitie osobných údajov pre potreby prijatia."}>
              </Text>

              <Text className="text-box text-box--justify" style={{"maxWidth":""}} content={"<span style=\"font-weight: bold;\">Termín podania žiadosti:</span>"}>
              </Text>

              <Text className="text-box text-box--justify" style={{"maxWidth":""}} content={"Žiadosť s potrebnými dokladmi posielajte poštou alebo elektronicky v termíne do 21.10. 2020. Uchádzači, ktorí splnia podmienky budú pozvaní na osobný pohovor."}>
              </Text>

              <Text className="text-box text-box--justify" style={{"maxWidth":""}} content={"<span style=\"font-weight: bold;\">Forma odmeňovania:</span>"}>
              </Text>

              <Text className="text-box text-box--justify" style={{"maxWidth":""}} content={"V zmysle zákona NR SR č. 553/2003 Z.z. o odmeňovaní niektorých zamestnancov pri výkone práce vo verejnom záujme a o doplnení niektorých zákonov v znení neskorších predpisov. Asistent bude pracovať v materskej škole a bude sa podieľať na realizácii projektu ,,Pomáhajúce profesie v edukácii detí a žiakov II.\""}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <SeparateLine className="pb--10 pt--10" name={"5jmhmte7abx"} layout={"l1"}>
          
          <SeparateLineWrap className="--full" fullscreen={true}>
          </SeparateLineWrap>

        </SeparateLine>


        <Column className="pb--30 pt--30" name={"drk6ovbmfgg"}>
          
          <ColumnWrap className="column__flex el--2 flex--center" columns={"2"}>
            
            <ColumnWrapper >
              
              <Subtitle className="subtitle-box subtitle-box--center" style={{"color":"var(--color-variable-1)"}} content={"Kontakt<br>Tel. číslo: 0917 385 684<br>Email:&nbsp;smsebgbr@gmail.com<br>"}>
              </Subtitle>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <ContactForm className="--shape2 --shape4 --style3 --shadow5 --right" action={"/contact"} fields={[{"name":"Celé meno","type":"text","required":true,"placeholder":"Zadajte celé meno"},{"name":"Kontaktný e-mail","type":"email","required":true,"placeholder":"Zadajte Váš e-mail"},{"name":"Telefónne číslo","type":"text","placeholder":"Telefón +421"},{"name":"Správa","type":"textarea","required":true,"placeholder":"Zadajte text správy"},{"name":"Odoslať","type":"submit"}]}>
              </ContactForm>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <SeparateLine className="pb--10 pt--10" name={"ub4gf9iph3"} layout={"l1"}>
          
          <SeparateLineWrap className="--full" fullscreen={true}>
          </SeparateLineWrap>

        </SeparateLine>


        <Fullmap className="--style2" name={"l221ntfwni"} style={{"backgroundColor":"rgba(255, 255, 255, 1)"}}>
          
          <FullmapWrap className="pb--20 pt--20" style={{"backgroundColor":"rgba(255, 255, 255, 1)"}}>
            
            <Subtitle className="subtitle-box subtitle-box--style2 subtitle-box--center" content={"Školská 5,977 01 Brezno"}>
            </Subtitle>

          </FullmapWrap>

          <FullmapWrap className="--full">
            
            <FullmapCover zoom={"15"} place={"Školská 5 ,977 01 Brezno"}>
            </FullmapCover>

          </FullmapWrap>

        </Fullmap>

      </Layout>
    </ThemeWrapper>
  )
}